export default function SocialLinks() {
  return (
    <div className="social_wrapper">
      {/* <ul>
        <li className="instagram">
          <a target="_blank" rel="noreferrer" title="Instagram" href="https://www.instagram.com/munstrography/">
            <i className="fa fa-instagram"></i>
          </a>
        </li>
        <li className="facebook">
          <a target="_blank" rel="noreferrer" href="https://www.facebook.com/munstrography/">
            <i className="fa fa-facebook-official"></i>
          </a>
        </li>
      </ul> */}
    </div>
  );
}
